<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            我的积分订单
        </div>
        <div class="vip-content-inner">
            <div class="filter-form">
                <div class="item">
                    <div class="label">下单时间:</div>
                    <div class="con">
                        <el-date-picker
                            v-model="orderform.ordertime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="item">
                    <div class="label">订单号:</div>
                    <div class="con">
                        <el-input v-model="orderform.orderNumber" placeholder="请输入订单号"></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="label">订单状态:</div>
                    <div class="con">
                        <el-select placeholder="全部状态" v-model="orderform.status">
                            <el-option
                                v-for="(item,index) in orderstatuslist"
                                :key="item.value"
                                :label="item.lable"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="item">
                    <div class="label">收货人:</div>
                    <div class="con">
                        <el-input v-model="orderform.deliveryUsername" placeholder="请输入收货人"></el-input>
                    </div>
                </div>
                <div class="item">
                    <div class="g-btns">
                        <div class="g-btn small" @click="clicksecher">搜索</div>
                        <div class="g-btn small white" @click="clickclear">清空</div>
                    </div>
                </div>
            </div>
            <div class="g-table-wrap">
                <table class="order-table g-table" v-loading="loading">
                    <thead>
                    <tr>
                        <td>订单号</td>
                        <td>下单日期</td>
                        <td>收货人</td>
                        <td>订单积分</td>
                        <td>订单状态</td>
                        <td>操作</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in myOrderList" :key="index">
                        <td>
                            <a :href="`#/billOrderDetail?id=${item.id}`">{{ item.orderNumber }}</a>
                        </td>
                        <td>{{ item.createTime }}</td>
                        <td>{{ item.deliveryUsername }}</td>
                        <td>{{ item.integralPoint }}</td>
                        <!--                    订单状态：0处理中,1已确认,2待发货,3部分发货,4已发货,5部分收货,6已完成,7已取消-->
<!--                        <td>{{ item.status==0?'处理中':item.status==1?'已确认':item.status==2?'待发货':item.status==3?'部分发货':item.status==4?'已发货':item.status==5?'部分收货':item.status==6?'已完成':item.status==7?'已取消':'' }}</td>-->
                        <td>{{ item.statusName}}</td>
                        <td>
                            <div class="tools">
                                <div class="btn" @click="jumpTo('/billOrderDetail',item.id)">查看订单</div>
                                <div class="btn" @click="jionCart(item)">再次下单</div>
                                <div class="btn" v-if="item.status!=0&&item.status!=1&&item.status!=2&&item.status!=7&&item.status!=8&&item.status!=9" @click="jumpTo('/billDeliveryInfo',item.id)">发货详情</div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="mat20 text_cen" v-if="myOrderList.length==0">暂无数据</div>

            </div>
            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="[10, 50, 100]"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {orderIntegralPage,customerIntegralCartsave,orderIntegralgoodslist} from "@/api/Product";
export default {
    name: "orderForBill",
    data() {
        return {
            loading:true, // 加载中
            orderform: {
                ordertime:"", // 下单时间
                orderNumber:"", // 订单编号
                deliveryUsername:"", // 收货人
                status:"", // 订单状态
            },
            myOrderList:[],
            current:1,
            size:10,
            total:0,

            orderstatuslist:[
                {
                    lable:'全部状态',
                    value:''
                },
                // {
                //     lable:'待支付',
                //     value:0
                // },
                // {
                //     lable:'已下单',
                //     value:1
                // },
                {
                    lable:'已下单',
                    value:2
                },
                {
                    lable:'部分发货',
                    value:3
                },
                {
                    lable:'已发货',
                    value:4
                },
                {
                    lable:'部分收货',
                    value:5
                },
                {
                    lable:'已收货',
                    value:6
                },
                // {
                //     lable:'已取消',
                //     value:7
                // },
                // {
                //     lable:'退款中',
                //     value:8
                // },
                // {
                //     lable:'已退款',
                //     value:9
                // },
                {
                    lable:'已完成',
                    value:10
                },
	            {
		            lable:'已关闭',
		            value:11
	            }
            ], // 订单状态list
        }
    },
    mounted() {
        this.getOrderList() // 订单商品列表
    },
    methods: {
        // 去详情
        jumpTo:function (path,id) {
            this.$router.push({path:path,query: {id:id}}) //

        },
        // 点击搜索
        clicksecher(){
            this.myOrderList=[]
            this.current=1
            this.size=10
            this.getOrderList() // 订单商品列表
        },
        // 点击清空
        clickclear(){
            this.orderform= {
                ordertime:"", // 下单时间
                orderNumber:"", // 订单编号
                deliveryUsername:"", // 收货人
                status:"", // 订单状态
            }
        },
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getOrderList();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getOrderList();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getOrderList();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getOrderList();
        },

        //    ----------------接口请求
        //
        // 获取订单列表
        getOrderList(){
            let beginTime='',endTime='' // 开始时间 结束时间
            if(this.orderform.ordertime){
                beginTime=this.$utils.formatDate(this.orderform.ordertime[0],'yyyy-mm-dd') + ' 00:00:00'
                endTime=this.$utils.formatDate(this.orderform.ordertime[1],'yyyy-mm-dd') + ' 23:59:59'
            }
            let param={
                customerId:localStorage.getItem('customId'),
                current:this.current,
                size:this.size,
                beginTime:beginTime, // 开始时间
                endTime:endTime, // 结束时间
                // ordertime:this.orderform.ordertime, // 下单时间
                orderNumber:this.orderform.orderNumber, // 订单编号
                deliveryUsername:this.orderform.deliveryUsername, // 收货人
                status:this.orderform.status, // 订单状态

            }
            orderIntegralPage(param).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    console.log('订单列表',res.data.data)

                    res.data.data.records.forEach(i=>{
                        i.createTime=i.createTime.slice(0,10)
                    })
                    this.myOrderList=res.data.data.records
                    this.total=Number(res.data.data.total)
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            }).catch(err=>{
                this.loading=false
            })
        },
        // 再次下单
        jionCart(item){
            // 获取订单商品
            orderIntegralgoodslist({orderId:item.id}).then((res) => {
                this.loading=false
                if (res.data.code==200) {
                    // console.log('订单商品列表',res.data.data)
                    res.data.data.forEach((i,index)=>{
                        let param={
                            integralGoodsId:i.integralGoodsId,
                            customerId:localStorage.getItem('customId'),
                            count:i.quantityPurchase
                        }
                        // 加入积分框
                        customerIntegralCartsave(param).then((res1) => {
                            if (res1.data.code==200) {
                                if(index+1==res.data.data.length){
                                    this.$message({
                                        type: 'success',
                                        message: "已加入积分框",
                                        duration: 2000
                                    });
                                }

                            }else{
                                // this.$message({
                                //     type: 'error',
                                //     message: res.data.message,
                                //     duration: 2000
                                // });
                            }
                        })
                    })
                }else{
                    // this.$message({
                    //     type: 'error',
                    //     message: res.data.message,
                    //     duration: 2000
                    // });
                }
            })

        },
    }
}
</script>

<style scoped>

</style>
